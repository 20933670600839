import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { useNavigate } from 'react-router';
import {domain} from '../../scripts/constants'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: "#1976D2",
     color: "white",
    //marginTop: "2rem",
    //padding: "1rem 0",
  },
});

function SignIn(props) {
    const { classes } = props;
    //const [username, setUsername] = useState("");
    //const [password, setPassword] = useState("");
    //const [error, setError] = useState(false);

    //const dispatch = useDispatch();
    const navigate = useNavigate();

    function isEmpty(str) {
        return (!str || str.length === 0);
    }
 
    const handleSubmit = async () => {
        var username = document.getElementById('username').value;
        var password = document.getElementById('password').value;

        if (isEmpty(username) || isEmpty(password)) {
            alert("Username or password cannot be empty");
            return;
        }          

        const credentials = { username, password };
        const url = "/api/v1/login";
        try {
            const response = await fetch(domain + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            }).then((response) => response.json());
            
            if (!response.error) {
                localStorage.setItem('authToken', JSON.stringify(response.data));
                //dispatch(setToken(response['data']['accessToken']));
           
                //navigate('/', { replace: true });
               // navigate('/admin', { replace: true });
               const params = new URLSearchParams(window.location.search);
               const returnUrl = params.get('returnUrl');
                if(returnUrl) console.log("Redirecting to return Url");
               // Redirect to returnUrl or default '/'
               window.location.href = returnUrl || '/';
            } else {
                if (response.message)
                alert(response.message);
            }
        } catch (e) {
            console.error('Fetch Error:', e);// alert(e);
        }
    
    };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar sesi&oacute;n
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="username">Nombre de usuario</InputLabel>
                      <Input id="username" type="text" name="username" autoComplete="username"  autoFocus />
                  </FormControl>
          <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">Contrase&ntilde;a</InputLabel>
                      <Input name="password" type="password" id="password"  autoComplete="current-password"  />
          </FormControl>
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox value="remember" color="primary" />}*/}
          {/*  label="Remember me"*/}
          {/*/>*/}
          <Button
                type="button"
                fullWidth
                variant="contained"
                //color="primary"
                className={classes.submit}
                onClick={handleSubmit} >
              Ingresar
          </Button>
        </form>
      </Paper>
    </main>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
