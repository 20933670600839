import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider  } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import DayView from '../components/calendar/DayView';
import {  Typography, Grid, Button, TextField, Select, MenuItem, InputLabel, Box, FormControl} from '@mui/material';
import {  Dialog, DialogTitle, DialogContent, DialogActions,
  Table, TableBody, TableCell, TableHead, TableRow, IconButton} from '@mui/material';
  import{ CompressOutlined, Delete, Edit }from '@mui/icons-material';
import esLocale from 'date-fns/locale/es';
import {getAuthToken, checkIfLoggedIn} from '../scripts/common'
import {domain} from '../scripts/constants'
import { useParams } from 'react-router-dom';
import { useTranslate } from '../components/TranslationContext';

export default function DateCalendarValue() {
  // '2022-04-17'
  const { dateParam } = useParams();

  var d;
  if(dateParam !== undefined){
    const [year, month, dd] = dateParam.split('-');
    const dateObject = new Date(year, month - 1, dd);
    //dayjs.extend(customParseFormat);
    d = dayjs(new Date(dateObject)); 
  }
  const [value, setValue] = useState( dateParam? d: dayjs( new Date()));
 
  const [isLoggedIn, setIsLoggedIn]=useState(false);
  const [day, setDay]= useState("Monday");
  const [startTime, setStartTime]= useState();
  const [endTime, setEndTime]= useState();
  const [editId, setEditId] = useState(null);
  const [workingHours, setWorkingHours]=useState([])
  const [authToken, setAuthToken]=useState("");
  const [open, setOpen] = useState(false);
  const { translate, translations } = useTranslate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (id) => {
    var row = workingHours.find((o) => o.id === id);
    setDay(row.day);
    setStartTime(row.startTime);
    setEndTime(row.endTime);
    setEditId(id);
    //setOpen(true);// handleClickOpen();
  };
  // function translate(key) {
 // return translate2(key, "Calendar")
  // }

  // const handleCreate = () => {
  //   setDay("");
  //   //setText("");
  //   setEditId(null);
  //   //setOpen(true);
  // };
  
  const actionWorkingHours = async () => {
    debugger;
    var url = `/api/workingHours/`;
    if (editId) url += editId.toString();
    const body = { day, startTime, endTime };
    
    try {
      const response = await fetch(domain + url, {
        method: editId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + authToken.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      debugger;
      if (!response.error) {
        alert(response.message);
        if (editId) {
          var updatedRows = workingHours.map((row) =>
            row.id === editId ? response.data : row
          );
          setWorkingHours(updatedRows);
        } else {
          var w= workingHours;
          w.push(response.data);
          setWorkingHours(w);
        }
        //handleClose();
      } else {
        if (response.message) alert(response.message);
      }
      setEditId(null);
    } catch (e) {
      alert(e);
    }
  };

  const deleteWorkingHours = async (id) => {
    debugger;
    var url = `/api/workingHours/${id}`;
    
    try {
      const response = await fetch(domain + url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + authToken.accessToken, // Replace with your access token
        },
       // body: JSON.stringify(body),
      }).then((response) => response.json());

      debugger;
      if (!response.error) {
        alert(response.message);
          var items= workingHours;
          var item= items.find(i=>i.id===response.data.id);
          items.splice(item, -1);
          setWorkingHours(items);
        
        //handleClose();
      } else {
        if (response.message) alert(response.message);
      }
      setEditId(null);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    // This will run after the component re-renders due to the state update
    console.log('Component re-rendered with updated value:', value);
  }, [value]);

  useEffect(() => {

    const fetchData = async () => {
      const url = `/api/workingHours`;
   
      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:"Bearer " + authToken.accessToken, // Replace with your access token
          },
        }).then((response) => response.json());
      
        if (!response.error) {
          //setServices( response.data.services);
          setWorkingHours(response.data);
          //setIntro(response.data.intro);
          //setIntroData(true);
          //doctor= domain+ response.data.intro.image;
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        alert(e);
      }
    };
  
    var loggedIn=checkIfLoggedIn();
    //setIsLoggedIn(loggedIn);

    if(loggedIn){
         setIsLoggedIn(loggedIn);
        var authToken= getAuthToken();
        setAuthToken(authToken);
        fetchData();
    }  
  },[isLoggedIn]);

  const changeValue= (newValue)=>{
    setValue(newValue);
   const formattedDate = newValue.format('YYYY-MM-DD').toString();
    window.history.replaceState(null, "Calendario", `/calendar/${formattedDate}`)
  };

  return translations.length > 0 ?   (
    <> 
  
<LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
  <DemoContainer components={['DateCalendar', 'DateCalendar']}>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 0 }}>
      <Typography variant="h5">
      {translate("calendar_title")}
      </Typography>
        <DemoItem //label="Controlled calendar"
        >
          <DateCalendar value={value} onChange={changeValue}
          //{(newValue) => setValue(newValue)} 
          />
        </DemoItem>
      
      </div>
      <div style={{ flex: 1 }}>
      {isLoggedIn?  <Grid xs={10} container spacing={2} justifyContent="flex-end">
      
      <Grid item sx={6}> <Button variant="contained" 
      onClick={handleOpen}
      > {translate("calendar_working_hours_button")}
      </Button></Grid>

    </Grid> :<></>}
        <DayView day={value} />
      </div>
    </div>
  </DemoContainer>

</LocalizationProvider>

<div>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {translate("Working Hours")}</DialogTitle>
        <DialogContent sx={{ width: '100%', minWidth:600 }}>
          {/* <DialogContentText>Enter comment info</DialogContentText> */}

          <div className="modal-content">
          <div>
          <Box sx={{m:2}} display="flex"  flexDirection="row"
      //alignItems="center"
      justifyContent="between"
      //height="100vh"
       >
            <FormControl>
        <InputLabel htmlFor="day">{translate("Day")}</InputLabel>
        <Select
          id="day"
          name="day"
          value={day}
           onChange={(e) => setDay(e.target.value)}
          required
        >
          <MenuItem value="Monday">{translate("Monday")}</MenuItem>
          <MenuItem value="Tuesday">{translate("Tuesday")}</MenuItem>
          <MenuItem value="Wednesday">{translate("Wednesday")}</MenuItem>
          <MenuItem value="Thursday">{translate("Thursday")}</MenuItem>
          <MenuItem value="Friday">{translate("Friday")}</MenuItem>
          <MenuItem value="Saturday">{translate("Saturday")}</MenuItem>
          <MenuItem value="Sunday">{translate("Sunday")}</MenuItem>
        </Select> 
        </FormControl>
        <Box m={1} />
      <TextField
        
        id="startTime"
        name="startTime"
        label={translate("Start Time")}
        type="time"
        value={startTime}
        onChange={(e) => setStartTime(e.target.value)}
        required
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5-minute intervals
        }}
      />
  <Box m={1} />
      <TextField
        
        id="endTime"
        name="endTime"
        label={translate("End Time")}
        type="time"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        required
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5-minute intervals
        }}
      />
  <Box m={1} />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        //fullWidth
        style={{ marginTop: '16px' }}
        onClick={actionWorkingHours}
      >
        {translate("Submit")}
      </Button>
      
      </Box>
          </div>

          <h3> {translate("calendar_working_hours_title")}</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("Day")}</TableCell>
                <TableCell>{translate("Hours")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workingHours.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{translate(row.day)}</TableCell>
                  <TableCell> 
                    {/* {row.hours} */}
                  
                  <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid #ccc"
      //padding="8px"
      margin="8px"
      borderRadius="4px"
    >
      <Typography variant="body1">{row.startTime} - {row.endTime}</Typography>
      <Box>
        <IconButton aria-label={translate("Edit")} onClick={e=>handleEdit(row.id)}>
          <Edit />
        </IconButton>
        <IconButton aria-label={translate("Delete")} onClick={e=>deleteWorkingHours(row.id)}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      
        </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}> {translate("Cancel")}</Button>
              {/* <Button onClick={(e) => actionsComments()}>Submit</Button> */}
            </DialogActions>
      </Dialog>
            </div>
    </>  
  ) : ( <div style={{ height: '100vh' }}></div>);
}
