import translations from '../assets/translations.json' //'../../public/translations.json';
//import { Translation } from '../models/Translation';

export function translate(key: string): string {
  const value = translations.find((t) => t.key.toLocaleLowerCase() === key.toLocaleLowerCase())?.value;
  if (value === undefined || value === null || value === '') {
    return key;
  }
  return value;
}

export const handleLogOut = () => {
  // const authToken = localStorage.getItem("authToken");
  localStorage.setItem('authToken', 'null');
  window.location.href="/";
  //window.location.reload();
  console.log('Logout clicked');
};

export const handleError=(e: any)=>{
  
  handleLogOut();
}
// export function translate(key: string, translations: Translation[]): string {
//   const value = translations.find((t) => t.htmlId.toLowerCase() === key.toLowerCase())?.text;
//   if (value === undefined || value === null || value === '') {
//     return key;
//   }
//   return value;
// }

export function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export const isEmpty = (str: string | null | undefined): boolean => {
  return !str || str.length === 0;
};

export function parseJwt(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getAuthToken = (): any | null => {
  const authTokenString = localStorage.getItem('authToken');
  if (!authTokenString) return null;
  const authToken = JSON.parse(authTokenString);
  if (!authToken) return null;
  return authToken;
};

export const checkIfLoggedIn = (): boolean => {
  const authTokenString = localStorage.getItem('authToken');
  if (!authTokenString) return false;
  const authToken = JSON.parse(authTokenString);
  if (!authToken) return false;
  if (authToken?.accessToken) {
    let decodedToken = parseJwt(authToken.accessToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log('Token expired.');
      return false;
    } else {
      return true;
    }
  }
  return false;
};


function parseJwt2(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const checkIfLoggedIn2 = (): boolean => {
  const authTokenString = localStorage.getItem('authToken');
  if (!authTokenString) return false;
  const authToken = JSON.parse(authTokenString);
  if (!authToken) return false;
  if (authToken?.accessToken) {
    const decodedToken = parseJwt(authToken.accessToken);
    const currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log('Token expired.');
      return false;
    } else {
      console.log('Valid token');
      return true;
    }
  }
  return false;
};
