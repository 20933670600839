import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useTranslate } from '../../components/TranslationContext';
import { useStyles } from '../../styles/footer';

export function Footer() {
  const classes = useStyles();

  const { translate } = useTranslate();
  const handleOpenLink = (url: string) => {
    console.log('Url clicked');
    window.location.href = url;
  };

  return (
    <Box className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.title}> {translate("app_title")}</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            {`${new Date().getFullYear()} | ${translate("Psychology")} | ${translate("Self Help")} | `}
            {/* <IconButton onClick={(e) => handleOpenLink('https://facebook.com/')}>
              <FacebookIcon sx={{ color: 'white' }}  />
            </IconButton> */}
                      
              <Tooltip title={translate("facebook_link")} arrow>
                <IconButton onClick={(e) => handleOpenLink(`${translate("facebook_link")}`)}>
                  <FacebookIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
          </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
