import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardMedia, CardContent } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { domain } from '../scripts/constants';
import Pricing from '../components/Pricing';
import { checkIfLoggedIn } from '../scripts/common';
import Services from  '../components/Services';
import doctor from '../../src/assets/images/doctor.jpg';
import UpdateIntro from '../components/home/UpdateIntro';
import { useTranslate } from '../components/TranslationContext';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  // ... (other styles)
  media: {
    width: '100%',
    height: 'auto',
  },
});

const Home: React.FC<any> = ({ classes }) => {
  const [prices, setPrices] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [intro, setIntro] = useState( {
      image: "",//doctor,
      name: "",
      description: "",
    });

 const [introData, setIntroData]= useState(false);
 const { translate2, translations } = useTranslate(); //translations

   function translate(key: string): string {
    return translate2(key, "Services");
  }

  useEffect(() => {
    // Fetch data here
    const fetchData = async () => {
      const url = `/api/services/intro`;

      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //Authorization:"Bearer " + authToken.accessToken, // Replace with your access token
          },
        }).then((response) => response.json());

        if (!response.error) {
          // setServices( response.data.services);
          setPrices(response.data.prices);
          var intro=response.data.intro;
          if(response.data.intro.image)
            intro.image=domain+ response.data.intro.image; // + "/download";
           setIntro(response.data.intro);
           setIntroData(true);
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        //console.log(e);
        alert(e);
      }
    };

    var loggedIn = checkIfLoggedIn();
    setIsLoggedIn(loggedIn);
    fetchData();
  }, []);

  return translations.length > 0 ?  (
    <div>
      {isLoggedIn && introData? <UpdateIntro intro={intro} /> : <></>}
      <Typography className={classes.title} gutterBottom component="h3">
        {translate("services_intro_title")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <div className="margin-b-30">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {/* {translate("services_intro_name")} */}
                  {intro.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" id="services_">
                  {translate("services_intro_description")}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <div className="promo-block-img-wrap image-container">
              {intro.image !==""?
              <CardMedia
                component="img"
                alt="Avatar"
                className={classes.media}
                //image={doctor}
                image={intro.image}
                title="Doctor"
                onError={(e) => console.error("Image failed to load", e)}
              />: <></> }
            </div>
          </Card>
        </Grid>
      </Grid>

      <Services />

      <Pricing prices={prices} />

    </div>
  ): ( <div style={{ height: '100vh' }}></div>);
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
