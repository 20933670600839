import { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Alert
} from "@mui/material";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import es from 'dayjs/locale/es'
import { useParams } from 'react-router-dom';

import { domain } from '../scripts/constants';
import { getAuthToken, checkIfLoggedIn } from '../scripts/common';//translate
import { useStyles } from '../styles/Comments';
import { useTranslate } from '../components/TranslationContext';

dayjs.extend(relativeTime);
dayjs.locale(es);


interface Comment {
  id: string;
  name: string;
  text: string;
  createdAt: string;
  status: string;
  visitor: string;
}
interface AuthToken {
  accessToken: string;
  // Add other properties if needed
}

export default function Comments() {
  const { id } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [text, setText] = useState('');
  const [authToken, setAuthToken] = useState<AuthToken | null>(null);
  const [commentStatus, setCommentStatus] = useState('Pending');
  const [rows, setRows] = useState<Comment[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { translate, translations } = useTranslate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleEdit(commentId: string) {
    var row = rows.find((o) => o.id === commentId);
    if(row!==undefined){
      setCommentStatus(row.status);
      setText(row.text);
    }
    setEditId(commentId);
    handleClickOpen();
  }

  const handleCreate = () => {
    setText('');
    setEditId(null);
    handleClickOpen();
  };

  const actionsComments = async () => {
    let url = `/api/comments/`;
    if (editId) url += editId;
    const body = isLoggedIn ? { status: commentStatus, text } : { text };

    try {
      const response = await fetch(domain + url, {
        method: editId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken?.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      if (!response.error) {
        alert(response.message);
        if (editId) {
          const updatedRows = rows.map((row) =>
            row.id === editId ? response.data : row
          );
          setRows(updatedRows);
        } else {
          // originalRows.push(response.data);
          // setRows(originalRows);
        }
        handleClose();
      } else {
        if (response.message) alert(response.message);
      }
    } catch (e) {
      console.log(e); //alert(e);
    }
  };

  useEffect(() => {
    const isLoggedIn = checkIfLoggedIn();

    // Check if authToken is not present or expired
    if (!isLoggedIn && id) {
        // Redirect to the login page
        window.location.href = `/login?returnUrl=/comments/${id}`; // Change the path to your login page
    }
}, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = `/api/comments/`;

      try {
        const response = await fetch(domain + url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken?.accessToken, // Replace with your access token
          },
        }).then((response) => response.json())
        .catch(error => console.error('Fetch Error:', error));

        if (!response.error) {
         var originalRows = response.data;
          setRows(originalRows);

          // setTimeout(openComment, 3000);
        } else {
          debugger;
          if (response.message) alert(response.message);
        }
      } catch (e) {
        console.error('Fetch Error:', e); //alert(e);
      }
    };

    var loggedIn = checkIfLoggedIn();

    if (loggedIn) {
      setIsLoggedIn(loggedIn);
      var authToken = getAuthToken();
      setAuthToken(authToken);
    }
    
    fetchData();
  }, []);

  useEffect(() => {
    if (id) {
      console.log('Url is', id);
      const row = rows.find((o) => o.id === id);
      if (row !== undefined) {
        // Set the comment status and text for editing
        setCommentStatus(row.status);
        setText(row.text);
      }

      // Set the "editId" to the "id" parameter and open the modal
      window.setTimeout(() => {
        setEditId(id);
        handleClickOpen();
      }, 2000);
    }
    else
    console.log("There is no url");
  }, [id, rows]);

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name: string) {
    const nameSplit = name.split(' ');
    let children = '';
    if (nameSplit.length > 1) children = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    else children = name[0];
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: children,
    };
  }

  const getSeverity = (status: string) => {
    if (status === 'Pending') return 'warning';
    if (status === 'Show') return 'success';
    if (status === 'Hide') return 'info';

    return 'error';
  };

  return translations.length > 0 ?  (
    <div style={{ minHeight: '70vh' }}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom component="h2">
              {translate('Comments')}
            </Typography>
          </Grid>
          {!isLoggedIn ? (
            <Grid item xs={6} container justifyContent="flex-end">
              <Button variant="contained" onClick={(e) => handleCreate()}>
                {translate('Add Comment')}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Toolbar>

      <div style={{ padding: 14 }} className="App">
        {rows.map((post, index) => (
          <Paper key={index} className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar
                  alt={post.name}
                  className={classes.avatar}
                  {...stringAvatar(post.visitor)}
                />
              </Grid>

              <Grid //justifyContent="left"
               item xs zeroMinWidth className={classes.content}>
                <Typography variant="h6" style={{ margin: 0 }}>
                  {post.visitor}
                </Typography>

                <Typography variant="body1">{post.text}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {dayjs(post.createdAt).fromNow()}
                </Typography>
              </Grid>
            </Grid>
            {isLoggedIn ? (
              <Grid container alignItems="center" spacing={2}>
                <Grid item //xs={{ marginLeft: '20px' }}
                >
                  <Alert severity={getSeverity(post.status)}>
                    {translate(post.status)}
                  </Alert>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={(e) => handleEdit(post.id)}>
                    {translate('Edit')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Divider variant="fullWidth" style={{ margin: '30px 0' }} />
          </Paper>
        ))}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate('Comment')}</DialogTitle>
        <DialogContent sx={{ width: '100%', minWidth: 500 }}>
          {isLoggedIn ? (
            <Select
              id="page_request_select"
              className={classes.select}
              value={commentStatus}
              onChange={(e) => setCommentStatus(e.target.value as string)}
              fullWidth
            >
              <MenuItem value={'Pending'}>{translate('Pending')}</MenuItem>
              <MenuItem value={'Show'}>{translate('Show')}</MenuItem>
              <MenuItem value={'Hide'}>{translate('Hide')}</MenuItem>
            </Select>
          ) : (
            <></>
          )}

          <TextareaAutosize
            minRows={3}
            maxRows={6}
            placeholder={translate('Enter your text here...')}
            className={classes.textarea}
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={isLoggedIn}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate('Cancel')}</Button>
          <Button onClick={(e) => actionsComments()}>{translate('Submit')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : ( <div style={{ height: '100vh' }}></div>);
}
