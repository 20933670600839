import React, { useState, useEffect } from "react";
//import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {
  Button,
  MenuItem,
  Select,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  TextField
} from "@mui/material";
import { styled } from "@mui/system";

import { domain } from  "../scripts/constants";
import data from '../assets/services.json';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { getAuthToken } from "../scripts/common"; //translate

interface AuthToken {
  accessToken: string;
  // Add other properties as needed
}

//const useStyles =((theme: Theme): React.CSSProperties => ({
const useStyles = makeStyles((theme: Theme)  => ({
  card: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  select: {
    width: "100%",
  },
  textarea: {
    width: "100%",
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    padding: 12,
    borderRadius: "12px 12px 0 12px",
    color: //theme.palette.mode === "dark"? theme.palette.grey[300] : 
    theme.palette.grey[900],
    background:
      //theme.palette.mode === "dark" ? 
      theme.palette.grey[900] ,
      //: "#fff",
    border: `1px solid ${
      //theme.palette.mode === "dark"? theme.palette.grey[700]:
         theme.palette.grey[200]
    }`,
    boxShadow: `0px 2px 2px ${
     // theme.palette.mode === "dark" ? theme.palette.grey[900] :
      theme.palette.grey[50]
    }`,
    "&:hover": {},
  },
}));

let originalRows: any[] = [];

export default function Services() {
  const [rows, setRows] = useState(originalRows);
  const [page, setPage] = useState<string>("services");
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [htmlId, setHtmlId] = useState("");
  const [editId, setEditId] = useState<number | null>(null);
  const [authToken, setAuthToken] = useState<AuthToken | null>(null);;
  const [dense, setDense] = useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //NOT USED
  const actionServices = async () => {
    var url = `/api/Services/`;
    if (editId) url += editId.toString();
    const body = { page, htmlId, text };

    try {
      const response = await fetch(domain + url, {
        method: editId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      if (!response.error) {
        alert(response.message);
        if (editId) {
          var updatedRows = originalRows.map((row) =>
            row.id === editId ? response.data : row
          );
          setRows(updatedRows);
        } else {
          originalRows.push(response.data);
          setRows(originalRows);
        }
        handleClose();
      } else {
        if (response.message) alert(response.message);
      }
    } catch (e) {
      alert(e);
    }
  };
  const Demo = styled("div")(({ theme }) => ({
    //backgroundColor: theme.palette.background.paper,
  }));

  useEffect(() => {
    // Fetch data here
    const fetchData = async () => {
      const url = `/api/services`;

      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
          },
        }).then((response) => response.json());

        if (!response.error) {
          originalRows = response.data;
          setRows(originalRows);
          //setPageCount(Math.ceil(originalRows.length / rowsPerPage));
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        alert(e);
      }
    };

    var authToken = getAuthToken();
    setAuthToken(authToken);
    fetchData();
    
  }, []);


  return (
    <>
      <Card className={classes.card}>
        <div className="margin-b-30">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Trastornos
                </Typography>
                <Demo>
                  <List dense={dense}>
                    {rows
                      .filter((r) => r.type === "Trastornos")
                      .map((row, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={row.name} />
                        </ListItem>
                      ))}
                  </List>
                </Demo>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Autoayuda
                </Typography>
                <Demo>
                  <List dense={dense}>
                    {rows
                      .filter((r) => r.type === "Autoayuda")
                      .map((row, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={row.name} />
                        </ListItem>
                      ))}
                  </List>
                </Demo>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Service</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter Service info</DialogContentText>

          <Select
            id="page_request_select"
            className={classes.select}
            value={page}
            onChange={(e) => setPage(e.target.value as string)}
            fullWidth
          >
            <MenuItem value={"services"}>Services</MenuItem>
            <MenuItem value={"calendar"}>Calendar</MenuItem>
            <MenuItem value={"comments"}>Comments</MenuItem>
          </Select>

          <TextField
            autoFocus
            margin="dense"
            id="html_id"
            label="Html Id"
            type="text"
            fullWidth
            variant="standard"
            value={htmlId}
            onChange={(e) => setHtmlId(e.target.value)}
          />

          <TextareaAutosize
            minRows={3}
            maxRows={6}
            placeholder="Enter your text here..."
            className={classes.textarea}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={(e) => actionServices()}>Submit</Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
}
