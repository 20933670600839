import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CommentIcon from '@mui/icons-material/Comment';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { handleLogOut} from '../../scripts/common'//translate
import { useTranslate } from '../../components/TranslationContext';

function MainListItems() {

  const { translate } = useTranslate();
  return (
    <div>
      <ListSubheader inset>{translate("Admin")} </ListSubheader>
      {/* <ListItem button component={Link} to="/admin">         
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem> */}
      <ListItem button component={Link} to="/admin/services">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Servicios" />
      </ListItem>
      <ListItem button component={Link} to="/admin/translations">
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={translate("Translations")} />
      </ListItem>
      <ListItem button onClick={handleLogOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={translate("Logout")} />
      </ListItem>
    </div>
  );
}

function SecondaryListItems() {
  const { translate } = useTranslate();
  return (
    <div>
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary={translate("Services")}/>
      </ListItem>
      <ListItem button component={Link} to="/calendar">
        <ListItemIcon>
          <CalendarMonthIcon />
        </ListItemIcon>
        <ListItemText primary={translate("Calendar")} />
      </ListItem>
      <ListItem button component={Link} to="/comments">
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary={translate("Comments")} />
        {/* <ListItemText primary={<Link to="/comments">Comments</Link>} /> */}
      </ListItem>
    </div>
  );
}

export { MainListItems, SecondaryListItems };
