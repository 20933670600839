import React, { useState, useEffect } from "react";
import { useStyles } from "../../styles/common";
import {
  Button,
  Toolbar,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";

import { domain } from "../../scripts/constants";
import { getAuthToken } from "../../scripts/common";
import {useAlert } from '../../components/AlertContext'; 
import { useTranslate } from '../../components/TranslationContext';


interface UpdateIntroProps {
  intro: {
    image: string;
    name: string;
    description: string;
  }
}

interface AuthToken{
  accessToken: string
}

const UpdateIntro: React.FC<UpdateIntroProps> = ({ intro }) => {
  //const [selectedFile, setSelectedFile] = useState<string | File>(domain + intro.image);
  const [open, setOpen] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>(intro.image); //useState<string | null>(null);
  const [name, setName] = useState<string>(intro.name);
  const [description, setDescription] = useState<string>(intro.description);
  const [authToken, setAuthToken] = useState<AuthToken>();
  const { showAlert } = useAlert();
  const { translate } = useTranslate();

  const classes = useStyles();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      //setSelectedFile(URL.createObjectURL(file)); //setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    handleClickOpen();
  };

  const actionIntro = async () => {
    const url = `/api/services/intro`;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    //formData.append("image", selectedFile);
    var fileInput = document.getElementById("contained-button-file");
   
    if (fileInput instanceof HTMLInputElement && fileInput.files?.length) {
      var file = fileInput.files[0];
      if(file)
         formData.append("image", file);
    } else {
      console.error("File input element not found or no file selected.");
    }

    try {
      const response = await fetch(domain + url, {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + authToken?.accessToken,
        },
        body: formData,
      }).then((response) => response.json());

      if (!response.error) {
        //alert(response.message);
        showAlert(response.message, 'success', 3000 );
        handleClose();
        window.location.reload();
      } else {
        if (response.message)
        showAlert(response.message, 'error');// alert(response.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    const authToken = getAuthToken();
    setAuthToken(authToken);
  }, []);

  // useEffect(() => {
  //   // const observer = new ResizeObserver(handleResize);
  //   // observer.observe(previewUrl);
  
  //   return () => {
  //     //observer.disconnect();
  //   };
  // }, [previewUrl]);

  return (
    <>
      <Toolbar>
        <Button variant="contained" onClick={handleEdit}>
         {translate("Update Intro")} 
        </Button>
      </Toolbar>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle> {translate("Intro")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={translate("name")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextareaAutosize
            minRows={3}
            maxRows={6}
            placeholder={translate("Enter your text here...")}
            className={classes.textarea}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Grid container direction="column" alignItems="center">
            {previewUrl ? (
              <Grid item>
                <img alt="Preview" width="100%" src={previewUrl} />
              </Grid>
            ) : null}

            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
               {translate("Select Image")} 
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileUpload}
                />
              </Button>
            </label>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> {translate("Cancel")}</Button>
          <Button onClick={actionIntro}> {translate("Submit")}</Button>
        </DialogActions>
      </Dialog>
      <br />
      
    </>
  );
};

export default UpdateIntro;
