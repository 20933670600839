import { makeStyles, Theme,createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontFamily: 'sans-serif',
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: '40px 20px',
      marginTop: 10,
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    content: {
      textAlign: 'left',
    },
    select: {
      width: '100%',
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      padding: 12,
      borderRadius: '12px 12px 0 12px',
      color:// theme.palette.mode === 'dark' ? theme.palette.grey[300] : 
        theme.palette.grey[900],
      background: //theme.palette.mode === 'dark' ? theme.palette.grey[900] :
       '#fff',
      border: `1px solid ${
        //theme.palette.mode === 'dark' ? theme.palette.grey[700] : 
        theme.palette.grey[200]
      }`,
      boxShadow: `0px 2px 2px ${
        //theme.palette.mode === 'dark' ? theme.palette.grey[900] :
         theme.palette.grey[50]
      }`,
      '&:hover': {},
    },
    table: {
      minWidth: 650,
    },
    textarea: {
      width: "100%",
      fontFamily: "IBM Plex Sans, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: 12,
      borderRadius: "12px 12px 0 12px",
      color: //theme.palette.mode === "dark"? theme.palette.grey[300] : 
      theme.palette.grey[900],
      //background:
        //theme.palette.mode === "dark" ? 
        //theme.palette.grey[900] ,
        //: "#fff",
      border: `1px solid ${
        //theme.palette.mode === "dark"? theme.palette.grey[700]:
           theme.palette.grey[200]
      }`,
      boxShadow: `0px 2px 2px ${
       // theme.palette.mode === "dark" ? theme.palette.grey[900] :
        theme.palette.grey[50]
      }`,
      "&:hover": {},
    },
  })
);
