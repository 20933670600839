import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  InputLabel,
  FormControl,
  Box,
  Select,
  MenuItem,
  Button,
  TextareaAutosize,
  TablePagination,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import Typography from "@mui/material/Typography";
import { domain } from "../../scripts/constants";
import { getAuthToken } from "../../scripts/common"; //translate

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useStyles } from "../../styles/translations";
import { useTranslate } from '../../components/TranslationContext';

let originalRows: any[] = [];

export default function Translations() {
  const [rows, setRows] = useState(originalRows);
  const [searched, setSearched] = useState("");
  const [pageId, setPageId] = useState("Todas");//Services
  const [page, setPage] = useState("Services");
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [htmlId, setHtmlId] = useState("");
  const [editId, setEditId] = useState<number | null>(null);
  const [pageOptions, setPageOptions] = useState<number[]>([5, 10, 25]);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
  const [pageCount, setPageCount] = useState(0); // Number of pages
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [authToken, setAuthToken] = useState<any>("");

  const { translate, translations } = useTranslate();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const requestSearch = (searchedVal: string) => {
  //   const filteredRows = originalRows.filter((row) => { 
  //     return row.text.toLowerCase().includes(searchedVal.toLowerCase());
  //   });
  //   setRows(filteredRows);
  // };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalRows.filter((row) => {
      const textMatches = row.text.toLowerCase().includes(searchedVal.toLowerCase());
      const hmtlIdMatches = row.htmlId.toLowerCase().includes(searchedVal.toLowerCase());
      const pageMatches = row.page.toLowerCase().includes(searchedVal.toLowerCase());
      return textMatches || hmtlIdMatches || pageMatches;
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleEdit = (id: number) => {
    var row = originalRows.find((o) => o.id === id);
    setHtmlId(row.htmlId);
    setText(row.text);
    setEditId(id);
    setPage(row.page);
    handleClickOpen();
  };

  const handleCreate = () => {
    setHtmlId("");
    setText("");
    setEditId(null);
    handleClickOpen();
  };

  const handleSelectPage = (event: React.ChangeEvent<{ value: string }>) => {
    setPageId(event.target.value as string);
  };

  const handleSelectPage2 = (value: string) => {
    if(value ==="Todas"){
      originalRows = translations;
      setRows(originalRows);
      setPageCount(Math.ceil(originalRows.length / rowsPerPage));
    }
    else
    setPageId(value);
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setCurrentPage(0); // Reset to the first page when changing rows per page
  };

  const actionTranslations = async () => {
    var url = `/api/translations/`;
    if (editId) url += editId.toString();
    const body = { page, htmlId, text };

    try {
      const response = await fetch(domain + url, {
        method: editId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      if (!response.error) {
        alert(response.message);
        if (editId) {
          var updatedRows = originalRows.map((row) =>
            row.id === editId ? response.data : row
          );
          setRows(updatedRows);
        } else {
          originalRows.push(response.data);
          setRows(originalRows);
        }
        handleClose();
      } else {
        if (response.message) alert(response.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    // Fetch data here
    const fetchData = async () => {
      const url = `/api/translations/${pageId}`;

      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken.accessToken, // Replace with your access token
          },
        }).then((response) => response.json());

        if (!response.error) {
          originalRows = response.data;
          setRows(originalRows);
          setPageCount(Math.ceil(originalRows.length / rowsPerPage));
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        alert(e);
      }
    };

    var authToken = getAuthToken();
    setAuthToken(authToken);
    if(pageId ==="Todas")
    {
      originalRows = translations;
      setRows(originalRows);
      setPageCount(Math.ceil(originalRows.length / rowsPerPage));
    }
    else
      fetchData();
  }, [pageId, rowsPerPage, translations]);

  return (
    <>
      <Toolbar>
        <Typography
          variant="h6"
          gutterBottom
          component="h2"
          className={classes.title}
        >
         {translate("Admin")}/{translate("Translations")}
        </Typography>

        <Box sx={{ minWidth: 120 }}>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="page-select-label">{translate("Page")}</InputLabel>
            <Select
              labelId="page-select-label"
              id="page_select"
              className={classes.select}
              value={pageId}
              
              onChange={e=> handleSelectPage2(e.target.value)}
            >
              <MenuItem value={"Todas"}>{translate("Todas")}</MenuItem>
              <MenuItem value={"Services"}>{translate("Services")}</MenuItem>
              <MenuItem value={"Calendar"}>{translate("Calendar")}</MenuItem>
              <MenuItem value={"Comments"}>{translate("Comments")}</MenuItem>
              <MenuItem value={"Admin"}>{translate("Admin")}</MenuItem>
              <MenuItem value={"None"}>{translate("None")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button variant="contained" onClick={(e) => handleCreate()}>
        {translate("Create")}  
        </Button>
      </Toolbar>

      <Paper>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder={translate("Search")}
        />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Id</TableCell> */}
                <TableCell align="left">{translate("Html Id")}</TableCell>
                <TableCell align="left">{translate("Text")}</TableCell>
                <TableCell align="right">{translate("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow key={row.id}>
                  {/* <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell> */}
                  <TableCell align="left">{row.htmlId}</TableCell>
                  <TableCell align="left">{row.text}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      onClick={(e) => handleEdit(row.id)}
                    >
                     {translate("Edit")} 
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {translate("Translation")} </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> {translate("Enter translation info")}</DialogContentText> */}

          <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="page_rquest_label">{translate("Page")}</InputLabel>
          <Select
            id="page_request_select"
            className={classes.select}
            label={translate("Page")}
            value={page}
            onChange={(e) => setPage(e.target.value)}
            fullWidth
          >
            <MenuItem value={"Services"}>{translate("Services")}</MenuItem>
            <MenuItem value={"Calendar"}>{translate("Calendar")}</MenuItem>
            <MenuItem value={"Comments"}>{translate("Comments")}</MenuItem>
            <MenuItem value={"Admin"}>{translate("Admin")}</MenuItem>
            <MenuItem value={"None"}>{translate("None")}</MenuItem>
          </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="html_id"
            label={translate("Html Id")}
            type="text"
            fullWidth
            variant="standard"
            value={htmlId}
            onChange={(e) => setHtmlId(e.target.value)}
          />

          <TextareaAutosize
            minRows={3}
            maxRows={6}
            placeholder={translate("Enter your text here...")}
            className={classes.textarea}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("Cancel")} </Button>
          <Button onClick={(e) => actionTranslations()}>{translate("Submit")}</Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
}
