import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: "#1976D2",
      color: "white",
      marginTop: "2rem",
      padding: "1rem 0",
    },
    title: {
      fontSize: "1.5rem",
    },
    subtitle: {
      fontSize: "1rem",
      display: "flex",
      alignItems: "center",
    },
  }));