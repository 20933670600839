import React, { useState, useEffect, createContext, useContext } from 'react';
import { domain } from '../scripts/constants';
import { Translation } from '../models/Translation';

// Assuming `Translation` type is available
// type Translation = {
//   htmlId: string;
//   text: string;
//   page: string;
// };

const TranslationContext = createContext(undefined);

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState([]);

  const translate = (key) => {
    const value = translations.find((t) => t.htmlId?.toLowerCase() === key?.toLowerCase())?.text;
    if (value === undefined || value === null || value === '') {
      return key;
    }
    return value;
  };

  const translate2 = (key, page) => {
    const value = translations.find(
      (t) => t.htmlId?.toLowerCase() === key?.toLowerCase() && t.page?.toLowerCase() === page.toLowerCase()
    )?.text;
    if (value === undefined || value === null || value === '') {
      return key;
    }
    return value;
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      const url = `/api/translations`;

      try {
        const response = await fetch(domain + url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
        .catch(error => console.error('Fetch Error:', error));

        if (!response.error) {
          setTranslations(response.data);
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        console.error('Fetch Error:', e);
      }
    };

    fetchTranslations();
  }, []);

  const contextValue = {
    translations,
    translate,
    translate2,
  };

  return <TranslationContext.Provider value={contextValue}>{children}</TranslationContext.Provider>;
};

export const useTranslate = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslate must be used within a TranslationProvider');
  }
  return context;
};
