import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './pages/auth/SignIn';
import{checkIfLoggedIn} from './scripts/common'
import Home from './pages/Home';
import Dashboard from './pages/dashboard/Dashboard';
import Admin from './pages/admin/Admin';
import Calendar from './pages/Calendar';
import Comments from './pages/Comments';
import Translations from './pages/admin/Translations';
//import Translations from './pages/admin/Translations';;
import Services from './pages/admin/Services';

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

// const authComponent = (Component: React.ComponentType) : React.ComponentType => {
//   const loggedIn = checkIfLoggedIn();
//   return loggedIn ? <Component /> : <Navigate to="/login" />;
// };

const authComponent = (Component: React.ComponentType<any>): React.ComponentType => {
  const loggedIn = checkIfLoggedIn();

  const AuthenticatedComponent: React.ComponentType = (props) => {
    if (loggedIn) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return AuthenticatedComponent;
};

// Define your components like Home, Dashboard, Admin, Calendar, Comments, Translations, etc.

root.render(

  //<App />
 //return (<App />) ; 

  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          {/* Layout */}
          <Route index element={<Home />} />
          {/* Home */}
          <Route path="services" element={<Home />} />
          <Route path="servicios" element={<Home />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="calendar/:dateParam" element={<Calendar />} />
          <Route path="calendario" element={<Calendar />} />
          <Route path="comments" element={<Comments />} />
          <Route path="commentarios" element={<Comments />} />
          <Route path="comments/:id" element={<Comments />} />
          <Route path="commentarios/:id" element={<Comments />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>

        <Route path="login" element={<SignIn />} />
        <Route path="signin" element={<SignIn />} />

        <Route path="/admin" element={<Dashboard />}>
         <Route index element={authComponent(Translations)} />
          <Route path="services" element={<Services />} />
          <Route path="translations" element={<Translations/>} /> 
        </Route>
      </Routes>
    </BrowserRouter>
 
  </React.StrictMode>
);


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );