import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    appBar: {
      backgroundColor: "#2196F3",
    },
    title: {
      flexGrow: 1,
    },
    button: {
      margin: "0 16px",
    },
    textarea: {
      width: "100%",
      fontFamily: "IBM Plex Sans, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: 12,
      borderRadius: "12px 12px 0 12px",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[300]
          : theme.palette.grey[900],
      background:
        theme.palette.mode === "dark" ? theme.palette.grey[900] : "#fff",
      border: `1px solid ${
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[200]
      }`,
      boxShadow: `0px 2px 2px ${
        theme.palette.mode === "dark"
          ? theme.palette.grey[900]
          : theme.palette.grey[50]
      }`,
      "&:hover": {},
    },
    table: {
      minWidth: 650,
    },
  }));
  