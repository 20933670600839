import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router';
import { translate } from '../scripts/common';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

interface PricingProps {
  prices: Array<{
    title: string;
    subheader: string;
    price: string;
    details: string | null;
  }>;
}

const Pricing: React.FC<PricingProps> = ({ prices }) => {
  const navigate = useNavigate();

  const gotToCalendar = () => {
    navigate('/calendar', { replace: false });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="h3"
          style={{ flexGrow: 1 }}
          align="center"
          color="text.primary"
        >
          {translate("services_prices_title")}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {prices.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h5" variant="h3" color="text.primary">
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /sesión
                    </Typography>
                  </Box>
                  {tier.details !== null ? (
                    <Typography
                      //component="h7"
                      variant="subtitle1"
                      align="center"
                      key={tier.details}
                    >
                      {tier.details}
                    </Typography>
                  ) : null}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="outlined" onClick={gotToCalendar}>
                    Calendario
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Pricing;
