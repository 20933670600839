import React, { useState, useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//import CheckoutForm from './CheckoutForm';
import {domain} from '../../scripts/constants';

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import {translate} from '../../scripts/common';
import dayjs from 'dayjs';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51OJUZUDBLavDqimVIYit7cGFpZdc3hKvl4CvvFgjP3swAur4iihqS3hw8Ui2YoOxnYwdouSZXguAtX2qcuh8oipb00vJrRJzTC', {locale: 'es'}); 
//const stripePromise = loadStripe(process.env.CLIENT_KEY);

export default function Payment({isOnePerson, age, createMeeting, alert2, day, startTime, endTime}) {
  //const options = {
    // passing the client secret obtained from the server
    //clientSecret: 'sk_test_4eC39HqLyjWDarjtT1zdp7dc' //'{{CLIENT_SECRET}}',
  //};
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // replace this with your own server endpoint
    fetch(domain+"/api/payments/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "age": age,
        "isOnePerson": isOnePerson
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) =>{
        var price= data.price.price;
        window.document.getElementById("price").innerHTML= price;
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const options = {
    clientSecret,
  };

  
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // confirmParams: {
      //   return_url: domain + "/calendar",
      // },
      redirect: "if_required",
    });

debugger;
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error);
      alert2(result.error.message, "error");
    } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {

      const dia = dayjs(day, { format: 'DD.MMM' });
      const formattedDia = dia.format('DD.MMM');
      var m=`Gracias por reservar su sesion el dia ${formattedDia} de ${startTime} a ${endTime}`;
      alert2(m, "success");

      const {
        id,
        status,
        amount,
        livemode,
        capture_method,
        client_secret,
        confirmation_method,
        created,
        currency,
        payment_method,
        payment_method_types,
    } = result.paymentIntent;
    
    const nonSensitiveData = {
        id,
        status,
        amount,
        livemode,
        capture_method,
        client_secret,
        confirmation_method,
        created,
        currency,
        payment_method,
        payment_method_types
    };
    
    const stringObject = JSON.stringify(nonSensitiveData);
    localStorage.setItem('payment', stringObject);
 
    setTimeout(() => {
    createMeeting();
      }, "1000");

    } else {
      alert2(translate("Payment failed"), "error");
      // handleOther();
    }
  };
  const buttonStyle = {
    marginTop: '16px', // Adjust the value as needed
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {/* <button disabled={!stripe}>Submit</button> */}
      <Button type="submit" variant="contained" color="primary"  style={buttonStyle}>
              
      {translate("Pagar")}  
       </Button>
    </form>
  )
};


  return (
    // <Elements stripe={stripePromise} options={options}>
    //   <CheckoutForm />
    // </Elements>

<div>
{clientSecret && (
  <Elements options={options} stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
)}
</div>
  );
};