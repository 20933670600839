import React, { useState, useEffect,forwardRef } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {Box, TextField, TextareaAutosize, MenuItem, FormControl, Select, Step, Stepper, StepLabel, InputLabel} from '@mui/material';

import {domain} from '../../scripts/constants'
import { translate, getAuthToken, checkIfLoggedIn } from '../../scripts/common';

import {CLIENT_ID, APP_SECRET, ENVIRONMENT} from '../../config/config'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import MuiPhoneNumber from 'material-ui-phone-number';
import dayjs from 'dayjs';
import country_codes from '../../assets/country_codes.json'
import countries from '../../assets/countries.json'
import { useStyles } from '../../styles/common';
import '../../../src/styles/components/dayview.css';

import Payment from '../payment/Payment';

import { useTranslate } from '../../components/TranslationContext';

const steps = [ translate('Book Appointment'),  translate('Payment')];

const DayView = ({day}) => {

  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [events,setEvents]= useState([]);

  const formattedDate = day.format('YYYY-MM-DD').toString();
  const dayOfWeek = day.format('dddd').toString();
  //const formattedDate = day.format('YYYY-MM-DD').toString();
  const [authToken, setAuthToken]=useState("");
  const [isLoggedIn, setIsLoggedIn]=useState(false);
  const [defaultCountryCode, setDefaultCountryCode]=useState('ch');
  const { translate } = useTranslate();

 // function translate(key) {
 // return translate2(key, "Calendar")
  // }


  const handleSlotClick = (event, time) => {
    setSelectedSlot(time);
    setStartTime(time);
    
    const start = dayjs("2023-09-23T"+time, { format: 'HH:mm' });
    const newTime = start.add(40, 'minute');
    const end = newTime.format('HH:mm');
    
    setEndTime(end);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const alert2=( message, type, timeout=5000)=>{
    if(type!=="success" && type!=="error")
    return;
    setMessage(message);
    setType(type);
    setTimeOut(timeout)
    setOpen(true);
  }

useEffect(() => {
  // Fetch data here
  console.log("ENVIRONMENT is", ENVIRONMENT);
  const fetchMeetings = async () => {
    const url = `/api/calendar/${formattedDate}`;
 
    try {
      const response = await fetch(domain + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:"Bearer " + authToken?.accessToken, // Replace with your access token
        },
      }).then((response) => response.json());
    
      if (!response.error) {

        var meetings= response.data;
        meetings.forEach(m=>{
          var time=dayjs(m.start).format('HH:mm');
          m.time= time;
        });
  
        setEvents(meetings);
      } else {
        if (response.message) alert2(response.message, "error");
      }
    } catch (e) {
      alert(e);
    }
  };

  var loggedIn=checkIfLoggedIn();
    //setIsLoggedIn(loggedIn);

    if(loggedIn){
         setIsLoggedIn(loggedIn);
        var authToken= getAuthToken();
        setAuthToken(authToken);
    }  
    getCountryCodeFromIPAddress();
  fetchMeetings(); 
}, [formattedDate]);


// Book

 //const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());


  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [description, setDescription] = useState("");
  const [singleOrGroup, setSingleOrGroup] = useState(1);
   const [age, setAge]= useState(18);
  //const [date, setDate]= useState();

  //const handleClickOpen = () => {

  function handleOnChange(value) {
    console.log("whatsapp is ", value);
    setWhatsapp(value);
    // this.setState({
    //    phone: value
    // });
 }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  async function getCountryCodeFromIPAddress() {
    try {
      const response = await fetch('https://ipwho.is');
  
      if (!response.ok) {
        throw new Error(`HTTP request failed with status ${response.status}`);
      }
  
      const data = await response.json();
      const countryCode = data.country_code?.toLowerCase();
      if(countryCode!=="" || countryCode !==undefined)
        setDefaultCountryCode(countryCode);
    } catch (error) {
      console.error('Error getting country code from IP address:', error);
    }
  }
  
function validateInternationalPhoneNumber(phoneNumber) {
  // Define a regular expression pattern for international phone numbers.
  // This pattern allows for a plus sign (+) followed by digits, spaces, hyphens, and parentheses.
  const pattern = /^\+(?:[0-9]|\s|-|\(|\)){1,}$/;

  // Remove unwanted characters (like spaces, hyphens, and parentheses) from the input before checking if it matches the pattern.
  const sanitizedPhoneNumber = phoneNumber.replace(/[\s\-()]/g, '');

  // Use the test method of the regular expression to check if the sanitized phone number matches the pattern.
  const isValid = pattern.test(sanitizedPhoneNumber);
  
  var phoneLength = sanitizedPhoneNumber.length;
  return (phoneLength >= 7 && phoneLength <= 15) && isValid;
}

  const handleNext = () => {

    var isError=false;
    if(activeStep===0)
    {
      if(name==="" || name===null){
        isError=true;
        alert2(translate("Name cannot be empty", "error"));
      }
      if(singleOrGroup===1 &&( age===0 || age===null)){
        isError=true;
        alert2( translate("Age cannot be 0"), "error");
      }
      if(whatsapp===""|| whatsapp===null){
        isError=true;
        alert2(translate("Whatsapp cannot be empty"),"error");
      }
      
      if(!validateInternationalPhoneNumber(whatsapp)){
        isError=true;
        alert2(translate("Whatsapp not valid"),"error");
      }

    }

    if(isError) return;
    //Check validation

    console.log("Step", activeStep);

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const changeSingleOrGroup= (e)=>{
    var changed=e.target.value;
    setSingleOrGroup(changed);
  }

  const [open, setOpen] = useState(false);
  const[message, setMessage]= useState("");
  const [type, setType]= useState("success");
  const [timeOut, setTimeOut]= useState(6000);
  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const style = {"layout":"vertical"};
    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

const createMeeting = async () => {
  debugger;
  var url = `/api/meetings/`;
  //if (editId) url += editId.toString();
  const formattedDate = day.format('YYYY-MM-DD').toString();
  var from =  formattedDate+"T"+ startTime  ;//dayjs(day).format("yyyy-MM-dd HH:mm");
  var to =formattedDate+"T"+ endTime;

  const myData = localStorage.getItem('payment');
  localStorage.removeItem('payment');

  const body = { from, to, description, name, age, whatsapp, payment:myData};

  try {
    const response = await fetch(domain + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization:"Bearer " + authToken.accessToken, // Replace with your access token
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());

    debugger;
    if (!response.error) {

      alert2(response.message, "success");
      handleClose();
      handleCloseDialog();

      setTimeout(() => {
        window.location.reload(false);
      }, "3000");
    
    } else {
      //if (response.message) alert(response.message);
    }
    
  } catch (e) {
    alert(e);
  }
};


  return (
<>
<Snackbar open={open} autoHideDuration={timeOut} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
  
    <Container>
      <Typography variant="h5"> 
      {translate(dayOfWeek)} {formattedDate }
       {/* {{day}} */}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {events.map((event, index) => (
              <TableRow key={index}>
                <TableCell>{event.time}</TableCell>
                <TableCell>
                  {event.status === 'FreeSlot' ? (
                    <Button variant="contained" onClick={(e) => handleSlotClick(e, event.time)}>
                      {translate(event.title)}
                    </Button>
                  ) : (
                    event.status==='Booked'? (
                      <>
                      <Button variant="contained" color="success">
                        {/* onClick={(e) => handleSlotClick(e, event.time)} */}
                        {translate(event.status)}
                      </Button>
                      {isLoggedIn? <div>
                      <h5> {event.title}</h5>
                      <i>{event.description} </i> 
                      </div>: <> </>}
                                         
                      </>
                    )  :
                    (
                      <Button variant="contained" color="secondary" disabled>
                        {translate(event.status)}
                      </Button>
                    ) 
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

{/* date={selectedSlot} */}


<Dialog open={openDialog} onClose={e=> handleCloseDialog()} fullWidth maxWidth="md">
    <DialogTitle> {translate("Book Appointment")}</DialogTitle>
    <DialogContent>
      {/* <DialogContentText>Enter translation info</DialogContentText> */}


<Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            // labelProps.optional = (
            //   <Typography variant="caption">Optional</Typography>
            // );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
{ activeStep===0? 

          <Box
           component="form"
           sx={{ mt:2, '& .MuiTextField-root': { m: 1, width: '20ch' },
           
           display: 'flex',
           flexDirection: 'column',
           gap: '10px',       
          }}
           noValidate
           autoComplete="off"

           
    >
      <div>
        <TextField
          //error
          //id="outlined-error"
          label={translate("Date")}
          //defaultValue={date}
          disabled

          //autoFocus
          //margin="dense"
          type="text"
          fullWidth
          //variant="standard"
          value={formattedDate}
          //onChange={(e) => setName(e.target.value)}
        />
        <TextField
          value={startTime}
          label={translate("Start Time")}
          //type="time"
          //defaultValue="Hello World"
          onChange={(e) => setStartTime(e.target.value)}
          fullWidth
          disabled
        />

        <TextField
          value={endTime}
          label={translate("End Time")}
          //type="time"
          //defaultValue="Hello World"
          //onChange={(e) => setEndTime(e.target.value)}
          fullWidth
          disabled
        />
      </div>
      <div>
      
     
      </div>
      <div className='container'>

        <FormControl >
            <InputLabel>1 {translate("Person")} o {translate("Group")}</InputLabel>
        <Select
        //id="page_request_select"
        //className={classes.select}
        //defaultChecked={1}
        value={singleOrGroup}
        //onChange={(e) => console.log(e.target.value)}
        //onChange={(e) => setSingleOrGroup(e.target.value)}
        onChange={(e)=>changeSingleOrGroup(e)}
        fullWidth
      >
        <MenuItem value={1}>1 {translate("Person")}</MenuItem>
        <MenuItem value={2}> {translate("Couple")}</MenuItem>
      </Select>

      </FormControl>
        
        <TextField
          label={translate("Name")}
          placeholder= {translate('Enter Name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        
    
    { singleOrGroup ===1?   <TextField
          label= {translate("Age")}
          type='number'
          value={age}
         onChange={(e) => setAge(e.target.value)}
        />: null
     }
      
      <MuiPhoneNumber 
      defaultCountry={defaultCountryCode|| 'ch'} 
      //defaultCountry={'ch'}
      //defaultCountry='ch'
      label="Whatsapp"
      fullWidth
      //regions={['south-america', 'central-america', 'europe']}
       //regions={['north-america', 'carribean']}
       localization={countries}
       //excludeCountries={['ca', 'us']}
       //onlyCountries={country_codes}
       preferredCountries={country_codes}
        onChange={handleOnChange}/>
          {/* <TextField
          label="Whatsapp"
          placeholder='Ej: +1 234 567 89 01'
          value={whatsapp}
          onChange={(e) => setWhatsapp(e.target.value)}
          fullWidth
        /> */}
      </div>

      <TextareaAutosize
        minRows={3}
        maxRows={6}
        label={translate("Comment")}
        placeholder={translate("Enter your comment")}
        className={classes.textarea}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      /> 
    </Box>
: <Box sx={{mt:4, minWidth:500}} >


<Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
    
                    <Typography sx={{m:2}} component="h7" variant="h5" color="text.primary" >
                
                    <div id="price"></div>
                    </Typography>
                    <Typography variant="h8" color="text.secondary">
                      /sesión
                    </Typography>
</Box>

<div style={{ maxWidth: "750px", minHeight: "200px" }}>
{/* secret: APP_SECRET */}
    
<Payment isOnePerson={singleOrGroup===1?true:false} age={age}  createMeeting={createMeeting}
alert2={alert2} startTime={startTime} endTime={endTime} day={day}
 />
        </div>

 </Box>
}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button onClick={handleCloseDialog}> {translate("Cancel")} </Button>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {translate("Back")} 
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? translate('Finish') : translate( 'Next')}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>


    </DialogContent>
    <DialogActions>
      {/* <Button onClick={handleClose}>Cancel</Button> */}
      {/* <Button onClick={(e) => actionTranslations()}>Submit</Button> */}
    </DialogActions>
  </Dialog>
    </Container>  
    </>   
  );
};

export default DayView;
