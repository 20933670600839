// AlertContext.tsx
import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; //{ AlertProps }

type AlertType = 'success' | 'error';

interface AlertContextProps {
  showAlert: (message: string, type: AlertType, timeout?: number) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertType>('success');
  const [alertTimeOut, setAlertTimeOut] = useState<number>(6000);

  const showAlert = (newMessage: string, newType: AlertType, timeout = 5000) => {
    if (newType !== 'success' && newType !== 'error') return;
    setAlertMessage(newMessage);
    setAlertType(newType);
    setAlertTimeOut(timeout);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Fetch data here if needed
  }, [isOpen]);

  const contextValue: AlertContextProps = {
    showAlert,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <Snackbar open={isOpen} autoHideDuration={alertTimeOut} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
