import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  InputLabel,
  FormControl,
  Box,
  Select,
  MenuItem,
  Button,
  TextareaAutosize,
  TablePagination,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import Typography from "@mui/material/Typography";
import { domain } from "../../scripts/constants";
import { getAuthToken } from "../../scripts/common"; //translate

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useStyles } from "../../styles/translations";
import { useTranslate } from '../../components/TranslationContext';

let originalRows: any[] = [];

export default function Services() {
  const [rows, setRows] = useState(originalRows);
  const [searched, setSearched] = useState("");
  const [typeId, setPageId] = useState("Trastornos");
  const [type, setType] = useState("Trastornos");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [editId, setEditId] = useState<string | null>(null);
  const [pageOptions, setPageOptions] = useState<number[]>([5, 10, 25]);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
  const [pageCount, setPageCount] = useState(0); // Number of pages
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [authToken, setAuthToken] = useState<any>("");


  const [openPrice, setOpenPrice] = useState(false);
  const [regionPriceRows, setRegionPriceRows] = useState(originalRows);
  const [childPrice, setChildPrice]=useState(0);
  const [adultPrice, setAdultPrice]=useState(0);
  const [groupPrice, setGroupPrice]=useState(0);
  const [currency, setCurrency]= useState("USD");
  const [service, setService]= useState("");

  const { translate } = useTranslate();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickPriceOpen = () => {
    setOpenPrice(true);
  };
  const handlePriceClose = () => {
    setOpenPrice(false);
  };

  // const requestSearch2 = (searchedVal: string) => {
  //   const filteredRows = originalRows.filter((row) => { 
  //     return row.name.toLowerCase().includes(searchedVal.toLowerCase());
  //   });
  //   setRows(filteredRows);
  // };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalRows.filter((row) => {
      const nameMatches = row.name.toLowerCase().includes(searchedVal.toLowerCase());
      const typeMatches = row.type.toLowerCase().includes(searchedVal.toLowerCase());
      return nameMatches || typeMatches;
    });
    setRows(filteredRows);
  };
  

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleEdit = (id: string) => {
    var row = originalRows.find((o) => o.id === id);
    setName(row.name);
    setEditId(id);
    setType(row.type);
    handleClickOpen();
  };

  const handleDelete = (id: string) => {
    var row = originalRows.find((o) => o.id === id);
    setName(row.name);
    //setEditId(id);
    setType(row.type);
    actionTranslations2("DELETE", id); 
    //handleClickOpen();
  };

  const handleCreate = () => {
    setName("");
    setEditId(null);
    handleClickOpen();
  };

  const handleSelectPage = (event: React.ChangeEvent<{ value: string }>) => {
    setPageId(event.target.value as string);
  };

  const handleSelectPage2 = (value: string) => {
    setPageId(value);
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setCurrentPage(0); // Reset to the first page when changing rows per page
  };

  const actionTranslations2 = async (action, id) => { //POST, PUT, DELETE, GET
    var url = `/api/services/`;
    if (id) url += id.toString();
    
    if(action !=="POST" && !id){
      var message = "Cannot update without an id";
      console.error(message);
      alert(message);
    }
    const body = { type, name };

    try {
      const response = await fetch(domain + url, {
        method: action,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      if (!response.error) {
        alert(response.message);
        if (action==="PUT") {
          var updatedRows = originalRows.map((row) =>
            row.id === editId ? response.data : row
          );
          setRows(updatedRows);
        } else {
          originalRows.push(response.data);
          setRows(originalRows);
        }
        handleClose();
        window.location.reload();
      } else {
        if (response.message) alert(response.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  const actionTranslations = async () => {
    var url = `/api/services/`;
    if (editId) url += editId.toString();
    const body = { type, name };

    try {
      const response = await fetch(domain + url, {
        method: editId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());

      if (!response.error) {
        alert(response.message);
        if (editId) {
          var updatedRows = originalRows.map((row) =>
            row.id === editId ? response.data : row
          );
          setRows(updatedRows);
        } else {
          originalRows.push(response.data);
          setRows(originalRows);
        }
        handleClose();
      } else {
        if (response.message) alert(response.message);
      }
    } catch (e) {
      alert(e);
    }
  };


  const handleEditPrice = (id: string) => {
    var row = regionPriceRows.find((o) => o.id === id);
    setCurrency(row.currency);
    setEditId(id);
    setChildPrice(row.childPrice);
    setAdultPrice(row.adultPrice);
    setGroupPrice(row.groupPrice);
    setCurrency(row.currency);
    setService(row.service);
    handleClickPriceOpen();
  };

  const updateRegionPrice = async () => {
    var url = `/api/services/prices`;
    if (editId) url += `/${editId}`;
  
    try {
      const response = await fetch(domain + url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
        },
        body: JSON.stringify({ adultPrice, childPrice, groupPrice, currency }),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update region price.");
      }
  
      // const updatedRows = regionPriceRows.map((row) =>
      //   row.id === editId ? responseData.data : row
      // );

      const updatedRows = regionPriceRows.map((row) => {
        if (row.id === editId) {
          // Create a new object with properties from responseData.data and update service
          var data = responseData.data;
    
          data.region=row.region; 
          return data;
        } else {
          // Return the original row unchanged
          return row;
        }
      });
      
      setRegionPriceRows(updatedRows);
      alert(responseData.message);
      handlePriceClose();
    } catch (error) {
      alert(error.message || "An error occurred while updating region price.");
    }
  };
  

  useEffect(() => {
    // Fetch data here
    const fetchData = async () => {
      const url = `/api/services`;

      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
          },
        }).then((response) => response.json());

        if (!response.error) {
          originalRows = response.data;
          setRows(originalRows);
          setPageCount(Math.ceil(originalRows.length / rowsPerPage));
        } else {
          if (response.message) alert(response.message);
        }
      } catch (e) {
        alert(e);
      }
    };

    const getRegionPrices = async () => {
      const url = `/api/services/prices`;
      
      try {
        const response = await fetch(domain + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken?.accessToken, // Replace with your access token
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          throw new Error(responseData.message || "Failed to fetch data.");
        }
    
        setRegionPriceRows(responseData.data);
        //alert(responseData.message);
      } catch (error) {
        alert(error.message || "An error occurred while fetching region prices.");
      }
    };

    var authToken = getAuthToken();
    setAuthToken(authToken);
    fetchData();
    getRegionPrices();
    
  }, [typeId, rowsPerPage]);


  return (
    <div style={{ minHeight: '70vh' }} >
      <Toolbar>
        <Typography
          variant="h6"
          gutterBottom
          component="h2"
          className={classes.title}
        >
         {translate("Admin")}/{translate("Services")}
        </Typography>

        {/* <Box sx={{ minWidth: 120 }}>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="page-select-label">{translate("Type")}</InputLabel>
            <Select
              labelId="page-select-label"
              id="page_select"
              className={classes.select}
              value={typeId}
              
              onChange={e=> handleSelectPage2(e.target.value)}
            >
              <MenuItem value={"Autoayuda"}>{translate("Autoayuda")}</MenuItem>
              <MenuItem value={"Trastornos"}>{translate("Trastornos")}</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        <Button variant="contained" onClick={(e) => handleCreate()}>
        {translate("Create")}  
        </Button>
      </Toolbar>

      <Paper>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder={translate("Search")}
        />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Id</TableCell> */}
                <TableCell align="left">{translate("Name")}</TableCell>
                <TableCell align="left">{translate("Type")}</TableCell>
                <TableCell align="right">{translate("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow key={row.id}>
                  {/* <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell> */}
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      onClick={(e) => handleEdit(row.id)}
                      style={{ marginRight: '0.5rem' }}
                    >
                     {translate("Edit")} 
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={(e) => handleDelete(row.id)}
                      style={{ marginLeft: '0.5rem' }}
                    >
                     {translate("Delete")} 
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />


      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {translate("Service")} </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> {translate("Enter service info")}</DialogContentText> */}

          <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="type_select_label">{translate("Type")}</InputLabel>
          <Select
            id="type_select"
            className={classes.select}
            label={translate("Type")}
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
          >
            <MenuItem value={"Autoayuda"}>{translate("Autoayuda")}</MenuItem>
            <MenuItem value={"Trastornos"}>{translate("Trastornos")}</MenuItem>
          </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={translate("Name")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("Cancel")} </Button>
          <Button onClick={(e) => actionTranslations()}>{translate("Submit")}</Button>
        </DialogActions>
      </Dialog>

      <Toolbar style={{marginTop:"20px"}}> <Typography
          variant="h6"
          gutterBottom
          component="h2"
          className={classes.title}
        >
          {translate("Admin")} / {translate("Prices")}
        </Typography> </Toolbar>
     

      <Paper style={{marginTop:"10px"}}>   
    
  <TableContainer>
    
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">{translate("Region")}</TableCell>
          <TableCell align="left">{translate("Child Price")}</TableCell>
          <TableCell align="left">{translate("Adult Price")}</TableCell>
          <TableCell align="left">{translate("Group Price")}</TableCell>
          <TableCell align="left">{translate("Currency")}</TableCell>
          <TableCell align="right">{translate("Actions")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? regionPriceRows.slice(
              currentPage * rowsPerPage,
              currentPage * rowsPerPage + rowsPerPage
            )
          : regionPriceRows
        ).map((row) => (
          <TableRow key={row.id}>
            <TableCell align="left">{row.region}</TableCell>
            <TableCell align="left">{row.childPrice}</TableCell>
            <TableCell align="left">{row.adultPrice}</TableCell>
            <TableCell align="left">{row.groupPrice}</TableCell>
            <TableCell align="left">{row.currency}</TableCell>
            <TableCell align="right">
              <Button
                variant="outlined"
                onClick={(e) => handleEditPrice(row.id)}
                style={{ marginRight: '0.5rem' }}
              >
                {translate("Edit")}
              </Button>
              
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    rowsPerPageOptions={pageOptions}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={currentPage}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
</Paper>

      
<Dialog open={openPrice} onClose={handlePriceClose}>
  <DialogTitle>{translate("Service")}</DialogTitle>
  <DialogContent>
    <label>{service}</label>

    {/* Child Price */}
    <TextField
      margin="dense"
      id="child_price"
      label={translate("Child Price")}
      type="number"
      fullWidth
      variant="standard"
      value={childPrice}
      onChange={(e) => setChildPrice(parseFloat(e.target.value))}
    />

    {/* Adult Price */}
    <TextField
      margin="dense"
      id="adult_price"
      label={translate("Adult Price")}
      type="number"
      fullWidth
      variant="standard"
      value={adultPrice}
      onChange={(e) => setAdultPrice(parseFloat(e.target.value))}
    />

    {/* Group Price */}
    <TextField
      margin="dense"
      id="group_price"
      label={translate("Group Price")}
      type="number"
      fullWidth
      variant="standard"
      value={groupPrice}
      onChange={(e) => setGroupPrice(parseFloat(e.target.value))}
    />

    {/* Currency Select */}
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel id="currency_select_label">{translate("Currency")}</InputLabel>
      <Select
        id="currency_select"
        className={classes.select}
        label={translate("Currency")}
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        fullWidth
      >
        <MenuItem value={"USD"}>{translate("USD")}</MenuItem>
        <MenuItem value={"EUR"}>{translate("EUR")}</MenuItem>
        <MenuItem value={"CHF"}>{translate("CHF")}</MenuItem>
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={handlePriceClose}>{translate("Cancel")}</Button>
    <Button onClick={(e) => updateRegionPrice()}>{translate("Submit")}</Button>
  </DialogActions>
</Dialog>

    </div>
  );
}
